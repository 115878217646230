import React from "react";

interface DropdownContainerProps {
  /** Removes the top border to give the illusion of extending the selector */
  extend?: boolean;
}

/**
 * The container/card for dropdown options
 *
 * Props:
 *
 * `extend` -- Removes the top border to give the illusion of extending the
 * selector
 */
export default function DropdownContainer({
  children,
  extend,
  className,
  ...props
}: DropdownContainerProps & React.HTMLProps<HTMLDivElement>) {
  const defaultClass =
    "group bg-hmio-black-900 rounded-lg text-hmio-white [&>:not(ul)]:px-4";
  const classes = [defaultClass, className];
  classes.push(
    extend
      ? "rounded-t-none extend [&:first-child]:rounded-t-none"
      : "[&:first-child]:rounded-t-xl",
  );

  return (
    <div className={classes.join(" ")} {...props}>
      {children}
    </div>
  );
}
