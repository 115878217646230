import React from "react";
import { twMerge } from "tailwind-merge";

export default function DropdownListItem({
  children,
  className,
  bgColor = "bg-hmio-black-800",
  hoverColor = "bg-hmio-black-700",
  ...props
}: React.HTMLProps<HTMLLIElement> & {
  bgColor?: string;
  hoverColor?: string;
}) {
  const defaultClass = `text-hmio-black-400 hover:bg-hmio-black-800 hover:ease-linear duration-75 select-none cursor-pointer ${bgColor} hover:${hoverColor}`;
  const mergedClassName = twMerge(
    [defaultClass, className].join(" "),
    className,
  );
  return (
    <li className={mergedClassName} {...props}>
      {children}
    </li>
  );
}
