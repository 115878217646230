import { Link as RadixLink } from "@radix-ui/react-navigation-menu";
import Link from "next/link";

interface NavLinkProps {
  children: string;
  href: string;
}

/**
 * Wrapper around Radix-UI NavigationMenu.Link. This should only
 * be used within a Radix navigation menu.
 */
export default function NavLink({ children, href }: NavLinkProps) {
  return (
    <RadixLink asChild>
      <Link href={href} passHref>
        <a className="block">{children}</a>
      </Link>
    </RadixLink>
  );
}
