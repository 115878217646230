export enum FormatNumNullReturnValue {
  TBU = "TBU",
  ZERO = "0",
  DASH = "-",
  DOUBLE_DASH = "--",
}

/**
 * standardize, this for tokens
 *
 * if n < 1, we format to the 6th decimal
 * if n >= 1, we format to the 2nd decimal
 */
export const formatWholeNumberOrDecimal = (n: number | undefined) => {
  if (!n) {
    return FormatNumNullReturnValue.TBU;
  }

  // decimal
  if (n < 1) {
    return formatNum(n, 6, false);
  }
  // whole number
  return formatNum(n, 2, false);
};

export function formatNum(
  n: number | undefined,
  d: number = 0,
  flatZero = true,
  nullReturnVal: FormatNumNullReturnValue = FormatNumNullReturnValue.TBU,
): string {
  if (n === null) {
    return nullReturnVal;
  }

  if (n === undefined) {
    return nullReturnVal;
  }

  if (Number.isNaN(n)) {
    return nullReturnVal;
  }

  const absoluteValueN = Math.abs(n);

  const fixSign = (absoluteNumber: number) =>
    n === absoluteValueN ? absoluteNumber : -1 * absoluteNumber;

  if (absoluteValueN >= 1e3 && absoluteValueN < 1e6) {
    const roundedNumber = flatZero
      ? Number(n.toFixed(d)).toString()
      : n.toFixed(d);
    const [integer, decimal] = roundedNumber.split(".");

    return `${integer.toString().slice(0, -3)},${integer.toString().slice(-3)}${
      decimal ? "." : ""
    }${decimal || ""}`;
  }
  if (absoluteValueN >= 1e6 && absoluteValueN < 1e9)
    return (
      parseFloat((fixSign(absoluteValueN) / 1e6).toFixed(d)).toString() + "M"
    );
  if (absoluteValueN >= 1e9 && absoluteValueN < 1e12)
    return (
      parseFloat((fixSign(absoluteValueN) / 1e9).toFixed(d)).toString() + "B"
    );
  if (absoluteValueN >= 1e12 && absoluteValueN < 1e15)
    return (
      parseFloat((fixSign(absoluteValueN) / 1e12).toFixed(d)).toString() + "T"
    );
  if (absoluteValueN >= 1e15)
    return (
      parseFloat((fixSign(absoluteValueN) / 1e15).toFixed(d)).toString() + "Q"
    );

  return flatZero ? Number(n.toFixed(d)).toString() : n.toFixed(d).toString();
}

export const curriedFormatNum =
  (d: number, flatZero = false) =>
  (n: number) =>
    formatNum(n, d, flatZero);

// for -> ['>10000', '1000-10000', '0', '100-1000', '10-100', '<1', '1-10', 'null']
export const formatUSDOrderedKeys = (key: string | undefined): string => {
  if (key === undefined) {
    return "";
  }
  if (key.includes("-")) {
    let splitKeys = key.split("-");
    return splitKeys
      .map((key) => {
        return "$" + formatNum(parseInt(key));
      })
      .join("-");
  } else if (key.indexOf(">") === 0 || key.indexOf("<") === 0) {
    let truncatedString = key.substring(1);
    truncatedString = "$" + formatNum(parseInt(truncatedString));
    return key[0] + truncatedString;
  } else if (parseInt(key) === 0 || parseInt(key)) {
    return "$" + key;
  } else {
    return key;
  }
};

export const formatNumWithCommas = (n: number | undefined) => {
  if (n === undefined || n === null || isNaN(n)) {
    return FormatNumNullReturnValue.TBU;
  }

  return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const getThousandthDecimal = (n: number): number => {
  return n / 1000;
};

/**
 * Format Numbers in the thousands ( K -> Q )
 * i.e, 1010 -> 1.01k
 */
export function formatNumWithThousands(
  n: number | undefined,
  d: number = 0,
  flatZero = true,
  nullReturnVal: FormatNumNullReturnValue = FormatNumNullReturnValue.TBU,
): string {
  if (!n) {
    return nullReturnVal;
  }

  const absoluteValueN = Math.abs(n);

  const fixSign = (absoluteNumber: number) =>
    n === absoluteValueN ? absoluteNumber : -1 * absoluteNumber;

  if (absoluteValueN >= 1e3 && absoluteValueN < 1e6) {
    const thousandth = getThousandthDecimal(absoluteValueN);
    return parseFloat(thousandth.toFixed(d)).toString() + "K";
  }
  if (absoluteValueN >= 1e6 && absoluteValueN < 1e9)
    return (
      parseFloat((fixSign(absoluteValueN) / 1e6).toFixed(d)).toString() + "M"
    );
  if (absoluteValueN >= 1e9 && absoluteValueN < 1e12)
    return (
      parseFloat((fixSign(absoluteValueN) / 1e9).toFixed(d)).toString() + "B"
    );
  if (absoluteValueN >= 1e12 && absoluteValueN < 1e15)
    return (
      parseFloat((fixSign(absoluteValueN) / 1e12).toFixed(d)).toString() + "T"
    );
  if (absoluteValueN >= 1e15)
    return (
      parseFloat((fixSign(absoluteValueN) / 1e15).toFixed(d)).toString() + "Q"
    );

  return flatZero ? Number(n.toFixed(d)).toString() : n.toFixed(d).toString();
}

export function formatDecimalRules(n: number) {
  if (n >= 1) {
    // 2 decimal places
    return formatNum(n, 2, false);
  } else if (1 > n && n >= 0.1) {
    // 4 decimal places
    return formatNum(n, 4, false);
  } else if (0.1 > n && n >= 0.01) {
    // 5 decimal places
    return formatNum(n, 5, false);
  } else {
    // 9 decimal places
    return formatNum(n, 9, false);
  }
}

export function numOrNaN(n: number | undefined | null) {
  return n === undefined || n === null ? NaN : n;
}
