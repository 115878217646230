import React from "react";
import { twMerge } from "tailwind-merge";

export default function DropdownList({
  children,
  ...props
}: React.HTMLProps<HTMLUListElement> & { overrideClassName?: string }) {
  const className = twMerge(
    `text-sm
    [&>:first-child]:first:rounded-t-xl
    [&>:last-child]:last:rounded-b-xl
    [&>*]:px-4
    [&>*]:py-1
    group-[.extend]:[&>:first-child]:rounded-t-none`,
    props.className,
  );

  return (
    <ul className={className} {...props}>
      {children}
    </ul>
  );
}
