import Link from "next/link";
import styled from "styled-components";

const InnerATag = styled.a`
  display: contents;
  cursor: pointer;
`;

interface AnchorProps {
  // pass the link the anchor should direct the current document to
  to?: string;
  // if link should open in a new tab
  target?: string;
  // relationship between linked resource and current document -> https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/rel
  rel?: string;
  preventScroll?: boolean;
  children: React.ReactNode;

  className?: string;
}

// moon-sam: I legitimately don't get how this is different from CellLinkController but it works when that doesn't.
// CellLinkController seems to convert the a into a div sometimes.
const Anchor = ({
  to,
  children,
  target,
  rel,
  preventScroll = false,
  className,
}: AnchorProps) => {
  return to ? (
    <Link href={to} passHref scroll={!preventScroll}>
      <InnerATag target={target} rel={rel} href={to}>
        {children}
      </InnerATag>
    </Link>
  ) : (
    <>{children}</>
  );
};

export default Anchor;

export const AnchorTW = ({
  to,
  children,
  target,
  rel,
  preventScroll = false,
  className,
}: AnchorProps) => {
  return to ? (
    <Link href={to} passHref scroll={!preventScroll}>
      <a className={className} target={target} rel={rel} href={to}>
        {children}
      </a>
    </Link>
  ) : (
    <div className={className}>{children}</div>
  );
};
